import axios from "axios";

// const api_request = (headers) => {
//   return new axios.create({
//     baseURL: process.env.REACT_APP_API_URL,
//     timeout: 120000,
//     responseType: "blob",
//     headers: {
//       Accept: "application/octet-stream",
//       //   Accept: "application/pdf",
//       //   "Content-Type": "application/json",
//       //   "x-email": auth_data.email,
//       //   "x-guserid": auth_data.guserid,
//       //   "x-token": auth_data.token,
//     },
//   });
// };

const api_request = (headers) => {
  return new axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60000,
    // headers: {
    //   "Content-Type": "application/json",
    //   "x-email": auth_data.email,
    //   "x-guserid": auth_data.guserid,
    //   "x-token": auth_data.token,
    // },
  });
};

export const product_download_pdf_post = async (key, payload) => {
  return new Promise((resolve, reject) => {
    api_request()
      .post(`/product-download/pdf/${key}`, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        reject(err);
      });
  });
};

export const product_download_file_post = async (key, payload) => {
  return new Promise((resolve, reject) => {
    api_request()
      .post(`/product-download/file/${key}`, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        reject(err);
      });
  });
};
