import React, { useState } from "react";

import { CalendarOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Card, Tooltip, Button, Tag, Alert, notification, Modal } from "antd";
import { sanitizeInput } from "utils/xss";
import Support from "../../../Components/Common/ProductContentSupport";
const moment = require("moment-timezone");
/*{
   <Option value="service">Serviço</Option>
<Option value="session">Sessão</Option>
<Option value="consultation">Consulta</Option>
<Option value="consulting">Consultoria</Option>
<Option value="meeting">Reunião</Option>
<Option value="conference">Conferência</Option>
<Option value="encounter">Encontro</Option>
<Option value="presentation">Apresentação</Option> 
}*/

const serviceNames = {
  service: {
    singular: "Serviço",
    plural: "Serviços",
    singular_from: "do serviço",
  },
  session: {
    singular: "Sessão",
    plural: "Sessões",
    singular_from: "da sessão",
  },
  consultation: {
    singular: "Consulta",
    plural: "Consultas",
    singular_from: "da consulta",
  },
  consulting: {
    singular: "Consultoria",
    plural: "Consultorias",
    singular_from: "da consultoria",
  },
  meeting: {
    singular: "Reunião",
    plural: "Reuniões",
    singular_from: "da reunião",
  },
  conference: {
    singular: "Conferência",
    plural: "Conferências",
    singular_from: "da conferência",
  },
  encounter: {
    singular: "Encontro",
    plural: "Encontros",
    singular_from: "do encontro",
  },
  presentation: {
    singular: "Apresentação",
    plural: "Apresentações",
    singular_from: "da apresentação",
  },
  technical_support: {
    singular: "Serviço de Apoio Técnico",
    plural: "Serviços de Apoio Técnico",
    singular_from: "do serviço de apoio técnico",
  },
};

const IsNullUndefinedEmpty = (value) => {
  return value === null || value === undefined || value === "";
};

const Service = ({ item, purchase_id, product_id, index }) => {
  const [instructionsVisible, setInstructionsVisible] = useState({ visible: false, name: "" });

  const ServiceDetails = ({ service }) => {
    if (!service) return "";
    let duration = "";

    let serviceName = serviceNames[service.service_name || "service"];
    let totalMinutes = 0;

    if (service?.session_duration_h > 0 && IsNullUndefinedEmpty(service?.session_duration_m) === false) {
      totalMinutes += service.session_duration_h * 60;
    }
    if (service?.session_duration_m > 0 && IsNullUndefinedEmpty(service?.session_duration_m) === false) {
      totalMinutes += service.session_duration_m;
    }

    if (totalMinutes > 0) {
      const _duration = moment.utc(moment.duration(totalMinutes, "minutes").asMilliseconds());
      if (_duration.hours() > 0) {
        duration = (
          <>
            de{" "}
            <strong>
              <Tooltip title={`${_duration.format("H [hora(s)] [e] m [minuto(s)]")}`}>
                {_duration.format("H[h] m[m]")}
              </Tooltip>
            </strong>
          </>
        );
      } else {
        duration = (
          <>
            de{" "}
            <strong>
              <Tooltip title={`${_duration.format("m [minuto(s)]")}`}>{_duration.format("m[m]")}</Tooltip>
            </strong>
          </>
        );
      }
    }

    // Single
    if (service.service_type === "single") {
      return (
        <span>
          {serviceName.singular} {duration}
        </span>
      );
    }

    // Package
    if (service.service_type === "package") {
      return (
        <span>
          <strong>{service.number_of_sessions}</strong> {serviceName.plural} {duration}
        </span>
      );
    }
  };

  function formatInstructions(instructions) {
    if (!instructions) return "";

    const safeInstructions = sanitizeInput(instructions);

    // Replace page break (\n or \r) with <br> for page breaks
    let formattedInstructions = safeInstructions.replace(/(\r\n|\n|\r)/g, "<br>");

    // Replace url with <a href="URL">URL</a> for links
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    formattedInstructions = formattedInstructions.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');

    return formattedInstructions;
  }

  return (
    <>
      <Card
        size="small"
        type="inner"
        title={
          <>
            <strong>Serviço: </strong>
            <span style={{ fontWeight: 400 }}>{item.product_name}</span>
          </>
        }
        style={{ marginBottom: 7 }}
      >
        {item?.refunded !== true && (
          <div key={index} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <>
              <div className="products-content-item">
                <span style={{ marginLeft: 5, color: "#3e79f7" }}>
                  <ServiceDetails service={item?.service} />
                </span>
              </div>

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {/* If booking has instructions */}
                {item?.service?.instructions && (
                  <div className="mr-2">
                    <Tooltip title="Ver detalhes ou instruções" placement="topRight">
                      <Button
                        type="default"
                        onClick={() =>
                          setInstructionsVisible({
                            visible: true,
                            name: serviceNames[item.service.service_name || "service"].singular_from,
                          })
                        }
                      >
                        <InfoCircleOutlined /> Detalhes / Instruções
                      </Button>
                    </Tooltip>
                  </div>
                )}

                {/* If booking link is available, show the button */}
                {item?.service?.booking_link && (
                  <div>
                    <Tooltip
                      title={`Agendar ${serviceNames[item.service.service_name || "service"].singular}`}
                      placement="topRight"
                    >
                      <a href={item?.service?.booking_link} target="_blank" rel="noreferrer noopener">
                        <Button type="primary" className="mr-2">
                          <CalendarOutlined /> Agendar
                        </Button>
                      </a>
                    </Tooltip>
                  </div>
                )}
              </div>
            </>
            {IsNullUndefinedEmpty(item?.service?.booking_link) &&
              IsNullUndefinedEmpty(item?.service?.instructions) && (
                <Tag color="geekblue">Aguarda instruções do produtor</Tag>
              )}
          </div>
        )}
        {item?.refunded === true && (
          <>
            <Alert
              message="Serviço indisponível"
              description="Este serviço foi reembolsado ou está em processo de reembolso."
              type="error"
              showIcon
            />
          </>
        )}
        <Support data={item.support} />
      </Card>
      <Modal
        closable={true}
        onCancel={() => setInstructionsVisible({ visible: false, name: "" })}
        title={`Detalhes / Instruções ${instructionsVisible.name}`}
        visible={instructionsVisible.visible}
        footer={
          <Button type="primary" onClick={() => setInstructionsVisible({ visible: false, name: "" })}>
            Fechar
          </Button>
        }
      >
        <div dangerouslySetInnerHTML={{ __html: formatInstructions(item?.service?.instructions) }} />
      </Modal>
    </>
  );
};

export default Service;
