import axios from "axios";

const api_request = (headers) => {
  return new axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 15000,
    // headers: {
    //   "Content-Type": "application/json",
    //   "x-email": auth_data.email,
    //   "x-guserid": auth_data.guserid,
    //   "x-token": auth_data.token,
    // },
  });
};

export const product_is_valid_get = async (product_id) => {
  return new Promise((resolve, reject) => {
    api_request()
      .get(`/products/is-valid/${product_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        reject({ ...err });
      });
  });
};

export const product_post = async (product_id, product_offer, country, region, data) => {
  return new Promise((resolve, reject) => {
    if (product_offer === null || product_offer === undefined) product_offer = "main";

    api_request()
      .post(`/products/public/${product_id}/${product_offer}/${country}/${region}`, data)
      .then((res) => resolve(res))
      .catch((err) => {
        reject({ ...err });
      });
  });
};

export const product_advanced_get = async (product_id) => {
  return new Promise((resolve, reject) => {
    api_request()
      .get(`/products/public/advanced/${product_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        reject({ ...err });
      });
  });
};

export const product_thankyou_page_get = async (product_id) => {
  return new Promise((resolve, reject) => {
    api_request()
      .get(`/products/public/thankyou_page/${product_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        reject({ ...err });
      });
  });
};

export const product_metrics_post = async (product_id, payload) => {
  return new Promise((resolve, reject) => {
    api_request()
      .post(`/products/metrics/${product_id}`, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        reject({ ...err });
      });
  });
};
