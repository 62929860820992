/**
 * Checks a string for potential XSS and malicious code injection risks
 * @param {string} input - The input string to validate
 * @returns {Object} Validation result with safety status and potential threats
 */
function validateInput(input) {
  // If input is not a string or is empty, consider it safe
  if (typeof input !== "string" || input.trim() === "") {
    return {
      isSafe: true,
      threats: [],
    };
  }

  // Potential threats to check
  const threats = [];

  // XSS and script injection patterns
  const xssPatterns = [
    /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, // Full script tags
    /javascript:/gi, // JavaScript protocol
    /onerror\s*=/gi, // Event handlers
    /onload\s*=/gi,
    /onclick\s*=/gi,
    /onmouseover\s*=/gi,
    /expression\s*\(/gi, // CSS expressions
  ];

  // Malicious code patterns
  const maliciousPatterns = [
    /eval\s*\(/gi, // Eval usage
    /Function\s*\(/gi, // Dynamic function creation
    /window\.[a-zA-Z]+/gi, // Direct window object manipulation
    /document\.[a-zA-Z]+/gi, // Direct document object manipulation
    /\b(alert|confirm|prompt)\s*\(/gi, // Suspicious browser dialogs
  ];

  // HTML injection patterns
  const htmlInjectionPatterns = [
    /<\/?[a-z][\s\S]*>/gi, // Any HTML tag
    /&[#0-9a-z]+;/gi, // HTML entities
  ];

  // Combine all patterns
  const allPatterns = [...xssPatterns, ...maliciousPatterns, ...htmlInjectionPatterns];

  // Check for matches
  allPatterns.forEach((pattern) => {
    const matches = input.match(pattern);
    if (matches) {
      threats.push({
        pattern: pattern.toString(),
        matches: matches,
      });
    }
  });

  // Additional context-aware checks
  if (input.includes("<!--") || input.includes("-->")) {
    threats.push({
      pattern: "HTML Comment Injection",
      matches: ["Potential HTML comment injection detected"],
    });
  }

  return {
    isSafe: threats.length === 0,
    threats: threats,
  };
}

// Example usage and additional sanitization
function sanitizeInput(input) {
  const validation = validateInput(input);

  if (!validation.isSafe) {
    console.warn("Potential security threats detected:", validation.threats);
    return escapeHTML(input);
  }

  return input;
}

// HTML escaping function to neutralize HTML injection
function escapeHTML(unsafe) {
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}

// Export functions for use in modules
export { validateInput, sanitizeInput, escapeHTML };
